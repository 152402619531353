import { isArray, isBool } from '@ocp-zmarta/zmarta-cl'
import { getApplicationStatus, formatApplication } from '@utils/applications'
import { isNotEmpty } from '@utils'
import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    loading: true,
    hasApplications: false,
    ongoingApplications: null,
    pausedApplications: null,
    historicApplications: null,
    cancelledApplications: null
  },

  getters: {
    getApplications: state => state
  },

  actions: {
    async fetchAllApplications ({ commit, dispatch }) {
      try {
        const { applications } = await fetch(`${Vue.prototype.SERVICES.elskling}/se/contract/myApplications`, {
          credentials: 'include'
        }).then(res => res.json())

        if (isNotEmpty(applications)) {
          commit('setHasApplications', true)

          const receiptBaseUrl = Vue.prototype.SERVICES.elskling + `/helpers/receipt?countryCode=${Vue.prototype.COUNTRY}&brand=${Vue.prototype.BRAND}`

          commit('setOngoingApplications', applications.filter(application => getApplicationStatus(Vue.prototype.APPLICATION_STATUS, application.status) === 'ONGOING').map(application => formatApplication(application, receiptBaseUrl)))

          commit('setPausedApplications', applications.filter(application => getApplicationStatus(Vue.prototype.APPLICATION_STATUS, application.status) === 'PAUSED').map(application => formatApplication(application, receiptBaseUrl)))

          commit('setHistoricApplications', applications.filter(application => getApplicationStatus(Vue.prototype.APPLICATION_STATUS, application.status) === 'HISTORIC').map(application => formatApplication(application, receiptBaseUrl)))

          commit('setCancelledApplications', applications.filter(application => getApplicationStatus(Vue.prototype.APPLICATION_STATUS, application.status) === 'CANCELLED').map(application => formatApplication(application, receiptBaseUrl)))
        }
      } catch (e) {
        console.error(e)
      }

      commit('setLoading', false)
    }
  },

  mutations: {
    setLoading (state, v) {
      if (isBool(v)) {
        state.loading = v
      }
    },
    setHasApplications (state, v) {
      if (isBool(v)) {
        state.hasApplications = v
      }
    },
    setOngoingApplications (state, v) {
      if (isArray(v) && isNotEmpty(v)) {
        state.ongoingApplications = v
      }
    },
    setPausedApplications (state, v) {
      if (isArray(v) && isNotEmpty(v)) {
        state.pausedApplications = v
      }
    },
    setHistoricApplications (state, v) {
      if (isArray(v) && isNotEmpty(v)) {
        state.historicApplications = v
      }
    },
    setCancelledApplications (state, v) {
      if (isArray(v) && isNotEmpty(v)) {
        state.cancelledApplications = v
      }
    }
  }
}
