import { getProp } from '@ocp-zmarta/zmarta-cl'

export function getApplicationStatus (statuses, applicationStatus) {
  if (getProp(statuses, 'CANCELLED').find(status => status === applicationStatus.replace(/_/g, '-'))) {
    return 'CANCELLED'
  } else if (getProp(statuses, 'PAUSED').find(status => status === applicationStatus.replace(/_/g, '-'))) {
    return 'PAUSED'
  } else if (getProp(statuses, 'ONGOING').find(status => status === applicationStatus.replace(/_/g, '-')) ||
    new Date(getProp(applicationStatus, 'attributes', 'startDate')).getTime() >= Date.now()) {
    return 'ONGOING'
  } else {
    return 'HISTORIC'
  }
}

export function formatApplication (application, receiptBaseUrl) {
  return {
    providerLogo: { url: application.providerLogo, alt: application.providerNickname },
    productType: application.contractType,
    commitmentPeriod: Number(application.timeTiedup ?? 0),
    validityPeriod: Number(application.validityTime ?? 0),
    cancellationPeriod: Number(application.cancellationTime ?? 0),
    totalKwhPrice: Number(application.totalPrice ?? 0),
    receiptUrl: formatReceiptUrl(receiptBaseUrl, application.email, application.socialSecurityNumber, application.referenceNumber),
    applicationStatus: application.status,
    address: application.propertyAddress,
    startDate: application.startDate || application.movingInDate
  }
}

function formatReceiptUrl (baseUrl, email, ssn, referenceNumber) {
  if (ssn.length > 12) {
    ssn = ssn.slice(0, 8)
  } else {
    ssn = ssn.slice(0, 6)
  }
  return `${baseUrl}&email=${email}&socialSecurityNumber=${ssn}&referenceNumber=${referenceNumber}`
}
